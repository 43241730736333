.styledScroll::-webkit-scrollbar {
  width: 5px;
}

.styledScroll::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}

.styledScroll::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0); /* color of the scroll thumb */
  border-radius: 1rem; /* roundness of the scroll thumb */
  border: 1px solid rgb(136, 136, 136); /* creates padding around scroll thumb */
}
